// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pqnq2cwQ2DDzZ0etuAUx{margin-top:80px}.zMDFVA1hUeX06euv__pF{margin-top:30px}.qVYzp3pHHMDxAtLfRx8q{grid-row:3 !important}.iKyC9Z0m_LbzkRTffa9o{margin-top:155px}.lN7kGHwvsfmO7h0wEv7c{margin-top:80px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/textureDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CAGJ,sBACI,eAAA,CAGJ,sBACI,qBAAA,CAGJ,sBACI,gBAAA,CAGJ,sBACI,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"regular-texture-block": `Pqnq2cwQ2DDzZ0etuAUx`,
	"reduced-texture-block": `zMDFVA1hUeX06euv__pF`,
	"reflection-block": `qVYzp3pHHMDxAtLfRx8q`,
	"triplanar-texture-block": `iKyC9Z0m_LbzkRTffa9o`,
	"refraction-texture-block": `lN7kGHwvsfmO7h0wEv7c`
};
export default ___CSS_LOADER_EXPORT___;
