// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VzMkmKBlwmT12lwWz1J5{grid-row:2;height:140px;width:140px;overflow:hidden;border-bottom-left-radius:7px;border:#000 4px solid;border-left:0px;border-bottom:0px}.VzMkmKBlwmT12lwWz1J5 img{width:100%;height:100%;pointer-events:none}.VzMkmKBlwmT12lwWz1J5 img.Q7o4Tgy7BcrzOkq9wzlO{display:none}.Q7o4Tgy7BcrzOkq9wzlO{display:none}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/common.modules.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,YAAA,CACA,WAAA,CACA,eAAA,CACA,6BAAA,CACA,qBAAA,CACA,eAAA,CACA,iBAAA,CAEA,0BACI,UAAA,CACA,WAAA,CACA,mBAAA,CAEA,+CACI,YAAA,CAKZ,sBACI,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"texture-block": `VzMkmKBlwmT12lwWz1J5`,
	"empty": `Q7o4Tgy7BcrzOkq9wzlO`
};
export default ___CSS_LOADER_EXPORT___;
