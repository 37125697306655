// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T_SaHlDzAEJoTGDoBUSA{width:40px !important;grid-template-rows:0px 40px 0px !important;border-radius:40px !important;transform:translateY(-7px) !important}.YnstiuXdTQt48Lm920YA{display:none}.VJUk1XkNAsYRhKuFSLDz{transform:translateY(7px)}.E7hnGRL7SIrYQSp2jMDx{border-radius:40px}`, "",{"version":3,"sources":["webpack://./../../../tools/nodeEditor/dist/graphSystem/display/elbowDisplayManager.modules.scss"],"names":[],"mappings":"AAAA,sBACI,qBAAA,CACA,0CAAA,CACA,6BAAA,CACA,qCAAA,CAGJ,sBACI,YAAA,CAGJ,sBACI,yBAAA,CAGJ,sBACI,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"elbowBlock": `T_SaHlDzAEJoTGDoBUSA`,
	"hidden": `YnstiuXdTQt48Lm920YA`,
	"translatedConnections": `VJUk1XkNAsYRhKuFSLDz`,
	"roundSelectionBorder": `E7hnGRL7SIrYQSp2jMDx`
};
export default ___CSS_LOADER_EXPORT___;
